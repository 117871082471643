.riboon-box {
    position: relative;
}

.ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;

    &:before,
    &:after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #2980b9;
    }

    span {
        position: absolute;
        display: block;
        width: 150px;
        padding: 0.5rem 0;
        background-color: #3498db;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
        color: #fff;
        font: 700 18px/1 'Lato', sans-serif;
        text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        text-transform: uppercase;
        text-align: center;
    }
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;

    &:before,
    &:after {
        border-top-color: transparent;
        border-left-color: transparent;
    }

    &:before {
        top: 0;
        right: 0;
    }

    &:after {
        bottom: 0;
        left: 0;
    }

    span {
        right: -15px;
        top: 20px;
        transform: rotate(-45deg);
    }
}

/* top right*/
.ribbon-top-right {
    top: -10px;
    right: -10px;

    &:before,
    &:after {
        border-top-color: transparent;
        border-right-color: transparent;
    }

    &:before {
        top: 0;
        left: 0;
    }

    &:after {
        bottom: 0;
        right: 0;
    }

    span {
        left: -15px;
        top: 20px;
        transform: rotate(45deg);
    }
}

/* bottom left*/
.ribbon-bottom-left {
    bottom: -10px;
    left: -10px;

    &:before,
    &:after {
        border-bottom-color: transparent;
        border-left-color: transparent;
    }

    &:before {
        bottom: 0;
        right: 0;
    }

    &:after {
        top: 0;
        left: 0;
    }

    span {
        right: -15px;
        bottom: 20px;
        transform: rotate(225deg);
    }
}

/* bottom right*/
.ribbon-bottom-right {
    bottom: -10px;
    right: -10px;

    &:before,
    &:after {
        border-bottom-color: transparent;
        border-right-color: transparent;
    }

    &:before {
        bottom: 0;
        left: 0;
    }

    &:after {
        top: 0;
        right: 0;
    }

    span {
        left: -15px;
        bottom: 20px;
        transform: rotate(-225deg);
    }
}

.ribon-rank-1 {
    &:before,
    &:after {
        border-color: #c53021;
    }

    span {
        background-color: #e43;
    }
}

.ribon-rank-2 {

    &:before,
    &:after {
        border-color: #109050;
    }

    span {
        background-color: #2c7;
    }
}

.ribon-rank-3 {

    &:before,
    &:after {
        border-color: #c76e15;
    }

    span {
        background-color: #e82;
    }
}
