.d-icon {
    display: inline-block;
    background-image: url("../../../img/video/icon-video.png");
    background-size: 25rem auto;
    vertical-align: middle;
    margin-bottom: 3px;
}

.d-video-statistics {
    background-color: $gray-700;
    padding: 0.625rem 0;

    .d-statis-list {
        color: $gray-500;
        margin-bottom: 0;

        li {
            cursor: pointer;

            span {
                color: $green;
                font-size: 1.5rem;
            }

            i {
                @include demension(4.125rem, 4.125rem);

                margin-right: 0.75rem;

                &.icon1 {
                    background-position: 0 0;
                }

                &.icon2 {
                    background-position: -4.125rem 0;
                }

                &.icon3 {
                    background-position: -8.25rem 0;
                }
            }

            &:hover i {
                &.icon1 {
                    background-position: -12.375rem 0;
                }

                &.icon2 {
                    background-position: -16.5rem 0;
                }

                &.icon3 {
                    background-position: -20.625rem 0;
                }
            }
        }
    }
}

.d-video-title {
    margin-top: 2rem;

    i {
        @include demension(25rem, 1.5rem);

        &.d-t1 {
            background-position: 0 -5.5rem;
        }

        &.d-t2 {
            background-position: 0 -7rem;
        }

        &.d-t3 {
            background-position: 0 -8.5rem;
        }
    }
}
