// TWITTER HEART

$heart-width: 100px !default;
$heart-height: 100px !default;
$heart-walk: -2800px !default;

.heart {
    display: inline-block;
    vertical-align: middle;
    width: $heart-width;
    height: $heart-height;
    background: url("../../../img/heart.png") no-repeat;
    background-position: 0 0;
    background-size: cover;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transition-duration: 0s;

    &.active {
        transition-duration: 1s;
        background-position: $heart-walk 0;
    }
}

.heart-sm {
    width: $heart-width/2;
    height: $heart-height/2;

    &.active {
        background-position-x: $heart-walk/2;
    }
}
