body {
    background-color: $gray-100;
}

.fixed-top {
    z-index: 1051;
}

.navbar {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.modal-topest {
    z-index: 9999;
}

.title-divider {
    color: gray("500");
    font-size: 60%;
    vertical-align: text-top;
}

.title {
    .title-link {
        font-size: $font-size-sm;
        color: $primary;
    }
}

.carousel-control-right-corner {
    z-index: 1030;
    right: 0;
    top: 0;
}

.section-dark,
.section-image {
    background-color: #343434;
    background-size: 200% 240%;
    background: #232323;
    background: radial-gradient(ellipse at center, #585858 0, #232323 100%);
    background-size: 550% 450%;

    .card-plain {
        .card-description,
        .icon {
            color: hsla(0, 0%, 100%, .76);
        }

        .card-title,
        .icon i {
            color: #fff;
        }
    }

    .description {
        color: hsla(0, 0%, 100%, .76);
    }

    .title {
        color: #fff;
    }
}

.section,
.section-image {
    position: relative;
    padding: 80px 0;
    background-position: 50%;
    background-size: cover;

    .container {
        z-index: 2;
        position: relative;
    }
}

.section-image {
    &:after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0, 0, 0, .7);
    }
}

.section-image-bg10 {
    background-image: url('../../../img/bg10.jpg');
}

.nav-breadcrumb {
    font-size: 75%;

    .breadcrumb {
        padding: 0;
        background-color: transparent;
    }
}

.card-breadcrumb {
    .breadcrumb {
        margin-bottom: 0;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        content: "|";
        color: gray("400");
    }
}

.d-pills {
    padding-left: 0;
    padding-right: 0;
}

.card-hover-overlay {
    overflow: hidden;
    text-align: center;
    background: theme-color('primary');

    .card-img-overlay {
        overflow: hidden;
        padding: 50px 20px;

        .card-title {
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            position: relative;
            font-size: 17px;
            overflow: hidden;
            padding: 0.5em 0;
            background-color: transparent;
            margin-bottom: 0.2rem;

            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #fff;
                content: '';
                -webkit-transition: -webkit-transform 0.35s;
                transition: transform 0.35s;
                -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
            }
        }

        .card-text {
            color: #fff;
            opacity: 0;
            filter: alpha(opacity=0);
            -webkit-transition: opacity 0.35s,
            -webkit-transform 0.35s;
            transition: opacity 0.35s,
            transform 0.35s;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }

        // &:before {
        //     position: absolute;
        //     top: 20px;
        //     right: 20px;
        //     bottom: 20px;
        //     left: 20px;
        //     border: 1px solid #fff;
        //     content: '';
        //     opacity: 0;
        //     filter: alpha(opacity=0);
        //     -webkit-transition: opacity 0.35s,
        //     -webkit-transform 0.45s;
        //     transition: opacity 0.35s,
        //     transform 0.45s;
        //     -webkit-transform: translate3d(-20px, 0, 0);
        //     transform: translate3d(-20px, 0, 0);
        // }
    }

    img {
        display: block;
        position: relative;
        max-width: none;
        width: calc(100% + 20px);
        -webkit-transition: opacity 0.35s,
        -webkit-transform 0.35s;
        transition: opacity 0.35s,
        transform 0.35s;
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    &:hover {
        img {
            opacity: 0.4;
            filter: alpha(opacity=40);
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        .card-title:after {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        .card-text {
            opacity: 1;
            filter: alpha(opacity=100);
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }
}

.card-shadow {
    background-color: #fff;

    .card-header {
        @include box-shadow(none);

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    @include box-shadow($bmd-shadow-2dp);
}

.switch-player {
    width: 160px;
    height: 90px;

    .card-body {
        padding: 0 .875rem;
        min-height: auto;
    }

    &:after {
        -webkit-transition: all .4s linear;
        transition: all .4s linear;
    }

    &.active {
        cursor: default;
    }

    &.active:after,
    &:hover:after {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .stream-tag {
        position: absolute;
        right: .7rem;
        top: .5rem;
        z-index: 5;
    }
}

.lecture-pay{
    color:  #ff712b;
}

.lecture-free{
    color: #2ab27b;
}

.lecture-page {
    background-image: url("../../../img/video/bg-lecture.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #f6f5fa;
}

.card-social-buttons {
    right: -8px;
    top: -8px;
}

.lecture-content {
    .nav-pills{
        a{
            font-size: 16px;
        }
    }
    .lecture-content-notice{
        margin: 15px;
    }
    img {
        max-width: 100%;
    }
}
.lecture-share-modal{
    .modal-body{
        // padding: 0px;
        img{
            width: 100%;
        }
    }
}

.lecture-share {
    width: 10rem;
}

.lecture-tag {
    position: absolute;
    top: 0;
    right: 10px;
    color: #fff;
    padding: 0 5px;
    border-radius: 0 0 5px 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 75%;
}

.lecture-tag-pay {
    background-color: $pink-500;
}

.comc-footer-cp {
    background-color: #0b0c0c;
    color: #999;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    .comc-footer-link-beian {
        font-size: 0.8em;
        margin: 0;
        li{
            list-style-type: none;
            display: inline-block;
            padding-right: 5px;
            a{
                color: #777;
            }
        }
        &+li:before {
            content: "|";
            color: #666;
            margin-left: -9px;
            margin-right: 5px;
        }
    }
}

.dropdown-user,
h6,
.h6 {
    text-transform: unset !important;
}
